import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PeopleIcon from '@mui/icons-material/People';
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import StoreIcon from "@mui/icons-material/Store";
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ReceiptLongSharpIcon from '@mui/icons-material/ReceiptLongSharp';
import ReviewsIcon from '@mui/icons-material/Reviews';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import BugReportIcon from '@mui/icons-material/BugReport';
import MoneyIcon from '@mui/icons-material/Money';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import Logo from './../../assets/images/mowash-logo.png';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { Speed } from "@mui/icons-material";
const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  return (
    <div className="sidebarContainer">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className="company-logo">
            <img src={Logo} alt="Logo" title="Logo"/>
            <span className="logo">Mowash</span>
          </div>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <Link to="/performance" style={{ textDecoration: "none" }}>
            <li>
              <Speed className="icon" />
              <span>Performance</span>
            </li>
          </Link>
          <p className="title">LISTS</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PeopleIcon className="icon" />
              <span>Registerd Users</span>
            </li>
          </Link>
          <Link to="/active-users" style={{ textDecoration: "none" }}>
            <li>
              <HowToRegIcon className="icon" />
              <span>Active Users</span>
            </li>
          </Link>
          <Link to="/car-washes" style={{ textDecoration: "none" }}>
            <li>
              <LocalCarWashIcon className="icon" />
              <span>Registered Car Washes</span>
            </li>
          </Link>
            <li>
              <TimeToLeaveIcon className="icon" />
              <span>Client Cars</span>
            </li>
          <Link to="/bookings" style={{ textDecoration: "none" }}>
            <li>
              <EventNoteIcon className="icon" />
              <span>All Bookings</span>
            </li>
          </Link>
          <Link to="/car-washes" style={{ textDecoration: "none" }}></Link>
          <li>
            <CorporateFareIcon className="icon" />
            <span>Corporate Clients</span>
          </li>
          <li>
            <EventNoteIcon className="icon" />
            <span>Corporate Bookings</span>
          </li>
          <Link to="/expenses" style={{ textDecoration: "none" }}>
            <li>
              <ReceiptLongSharpIcon className="icon" />
              <span>Expenses</span>
            </li>
          </Link>
          <li>
            <SubscriptionsIcon className="icon" />
            <span>Recurring Washes</span>
          </li>
          <li>
            <CreditCardIcon className="icon" />
            <span>Payments</span>
          </li>
          <li>
            <MoneyIcon className="icon" />
            <span>Promo Codes</span>
          </li>
          <li>
            <MiscellaneousServicesIcon className="icon" />
            <span>Services</span>
          </li>
          <li>
            <ReviewsIcon className="icon" />
            <span>Reviews</span>
          </li>
          <li>
            <BugReportIcon className="icon" />
            <span>Bug Reports</span>
          </li>
          <li>
            <RecentActorsIcon className="icon" />
            <span>Complaints</span>
          </li>

          <p className="title">USEFUL</p>
          <li>
            <InsertChartIcon className="icon" />
            <span>Stats</span>
          </li>
          <li>
            <NotificationsNoneIcon className="icon" />
            <span>Notifications</span>
          </li>
          <p className="title">SERVICE</p>
          <li>
            <SettingsSystemDaydreamOutlinedIcon className="icon" />
            <span>System Health</span>
          </li>
          <li>
            <PsychologyOutlinedIcon className="icon" />
            <span>Logs</span>
          </li>
          <li>
            <SettingsApplicationsIcon className="icon" />
            <span>Settings</span>
          </li>
          <p className="title">USER</p>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          <li>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
