// import React from "react";
// import ReactDOM from "react-dom/client";
// import App from "./App";
// import { DarkModeContextProvider } from "./context/darkModeContext";
// import authReducer from './slices/authSlice'
// import Provider from '../node_modules/react-redux/es/components/Provider';
// import { configureStore } from '@reduxjs/toolkit';
// import bookingsReducer, { getActiveBookings, getLatestBookings,getBookingsToday } from "./slices/bookingsSlice";
// import usersReducer, { getAllUsers, getActiveUsers } from "./slices/usersSlice";
// import kpisReducer, { getKPIs, getBookingsWeeklyKPIs } from './slices/kpisSlice';
// import carWashesReducer, { getAllCarWashes } from './slices/carWashesSlice';
// import countriesReducer, { getCountries } from './slices/countriesSlice';
// import expensesReducer, { getAllExpenses } from './slices/expenseSlice';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// const store = configureStore({
//   reducer: {
//     auth: authReducer,
//     bookings: bookingsReducer,
//     users: usersReducer,
//     kpis: kpisReducer,
//     weeklyBookings: kpisReducer,
//     carWashes: carWashesReducer,
//     latestBookings: bookingsReducer,
//     bookingsToday: bookingsReducer,
//     countries: countriesReducer,
//     activeUsers: usersReducer,
//     expenses: expensesReducer
//   }
// })
// store.dispatch(getAllUsers());
// store.dispatch(getActiveUsers());
// store.dispatch(getKPIs());
// store.dispatch(getBookingsWeeklyKPIs());
// store.dispatch(getAllCarWashes());
// store.dispatch(getLatestBookings());
// store.dispatch(getBookingsToday());
// store.dispatch(getCountries());
// store.dispatch(getAllExpenses());
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <DarkModeContextProvider>
//         <App />
//       </DarkModeContextProvider>
//     </Provider>
//   </React.StrictMode>
// );

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {createRoot} from 'react-dom/client';
import { Provider } from 'react-redux'; // Corrected import
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import bookingsReducer, { getActiveBookings, getLatestBookings,getBookingsToday, getBookingsThisMonth } from "./slices/bookingsSlice";
import usersReducer, { getAllUsers, getActiveUsers } from "./slices/usersSlice";
import kpisReducer, { getKPIs, getBookingsWeeklyKPIs, getPerformanceKPIs } from './slices/kpisSlice';
import carWashesReducer, { getAllCarWashes } from './slices/carWashesSlice';
import countriesReducer, { getCountries } from './slices/countriesSlice';
import expensesReducer, { getAllExpenses } from './slices/expenseSlice';

// Create the Redux store
const store = configureStore({
  reducer: {
    auth: authReducer,
    bookings: bookingsReducer,
    users: usersReducer,
    kpis: kpisReducer,
    latestBookings: bookingsReducer,
    weeklyBookings: kpisReducer,
    carWashes: carWashesReducer,
    countries: countriesReducer,
    bookingsToday: bookingsReducer,
    activeUsers: usersReducer,
    expenses: expensesReducer,
    performanceKpis: kpisReducer,
    bookingsThisMonth: bookingsReducer
  },
  // Add middleware and DevTools configuration if needed
});

// Initial data loading
store.dispatch(getAllUsers());
store.dispatch(getActiveUsers());
store.dispatch(getKPIs());
store.dispatch(getBookingsWeeklyKPIs());
store.dispatch(getAllCarWashes());
store.dispatch(getLatestBookings());
store.dispatch(getBookingsToday());
store.dispatch(getCountries());
store.dispatch(getAllExpenses());
store.dispatch(getPerformanceKPIs())
store.dispatch(getBookingsThisMonth())

// Create a root
const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
