import "./bookingsList.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import BookingsData from "../../components/datatable/Bookings"

const BookingsList = () => {
  return (
    <div className="bookingsContainer">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <BookingsData/>
      </div>
    </div>
  )
}

export default BookingsList