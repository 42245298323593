import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import UserList from "./pages/usersList/UserList";
import CarWashList from "./pages/carWashesList/CarWashList";
import Single from "./pages/single/Single";
import New from "./pages/newUser/NewUser";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import RequireAuth from './components/RequireAuth/RequireAuth';
import BookingsList from './pages/bookingsList/BookingsList';
import RecentBookings from "./pages/recentBookings/RecentBookings";
import BookingsToday from "./pages/bookingsToday/BookingsToday";
import ActiveUsersList from "./pages/activeUsersList/ActiveUsersList";
import ExpensesList from "./pages/expenses/ExpensesList";
import NewExpense from "./pages/newExpense/NewExpense"
import List from "./components/table/Table";
import LineChart from "./components/chart/LineChart";
import Performance from "./pages/performance/Performance";
import BookingsThisMonth from "./pages/bookingsThisMonth/BookingsThisMonth";
function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route element={<RequireAuth/>}>
            <Route path="/">
            <Route index element={<Home />} />
            <Route path="users">
              <Route index element={<UserList />} />
              <Route path=":userId" element={<Single />} />
              <Route
                path="new-user"
                element={<New inputs={userInputs} title="Add New User" />}
            />
            </Route>
            <Route path="car-washes">
              <Route index element={<CarWashList />} />
              <Route path=":carWashId" element={<Single />} />
              <Route
                path="new-car-wash"
                element={<New inputs={userInputs} title="Add New Car Wash" />}
              />
            </Route>
            <Route path="performance">
              <Route index element={<Performance/>} />
            </Route>
            <Route path="bookings">
              <Route index element={<BookingsList/>} />
            </Route>
            <Route path="active-users">
              <Route index element={<ActiveUsersList />} />
            </Route>
            <Route path="expenses">
              <Route index element={<ExpensesList />} />
              <Route path=":expenseId" element={<Single />} />
              <Route
                path="new-expense"
                element={<New inputs={NewExpense} title="Add New Expense" />}
              />
            </Route>
            <Route path="products">
              <Route index element={<CarWashList />} />
              <Route path=":productId" element={<Single />} />
              <Route
                path="new"
                element={<New inputs={productInputs} title="Add New Product" />}
              />
            </Route>
            <Route path="1">
              <Route index element={<BookingsList />} />
              <Route path=":productId" element={<Single />} />
              <Route
                path="new"
                element={<New inputs={productInputs} title="Add New Product" />}
              />
            </Route>
            <Route path="recent-bookings">
              <Route index element={<RecentBookings />} />
              <Route path=":productId" element={<Single />} />
              <Route
                path="new"
                element={<New inputs={productInputs} title="Add New Product" />}
              />
            </Route>
            <Route path="bookings-today">
              <Route index element={<BookingsToday />} />
            </Route>
            <Route path="bookings-this-month">
              <Route index element={<BookingsThisMonth />} />
            </Route>
          </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;