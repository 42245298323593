import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/Axios';
import { useSelector } from "react-redux";


const initialState = {
    kpis: null,
    performanceKpis: null,
    weeklyBookings: null,
    status: ""
};
  

export const getKPIs = createAsyncThunk(
    "kpis/getKPIs",
    async (values, {rejectWithValue}) => {
        try {
            const response = await axios.get('/employees/getKPIs',
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'x-auth-token': localStorage.getItem('accessToken')
                },
                    withCredentials: false

                })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

export const getPerformanceKPIs = createAsyncThunk(
    "performanceKpis/getKPIs",
    async (values, {rejectWithValue}) => {
        try {
            const response = await axios.get('/employees/getPerformanceKPIs',
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'x-auth-token': localStorage.getItem('accessToken')
                },
                    withCredentials: false

                })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

export const getBookingsWeeklyKPIs = createAsyncThunk(
    "kpis/bookingsWeeklyKPIs",
    async (values, {rejectWithValue}) => {
        try {
            const response = await axios.get('/employees/getBookingsWeeklyKPIs',
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'x-auth-token': localStorage.getItem('accessToken')
                },
                    withCredentials: false

                })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

const kpisSlice = createSlice({
    name: "kpis",
    initialState,
    reducers: {},
    extraReducers : (builder) => {
        //Get KPIs 
        builder
        .addCase(getKPIs.pending,(state) => {
            return {
                ...state, status : "pending"}
        })
        .addCase(getKPIs.fulfilled, (state, action) => {
            return {
                ...state,
                kpis : action.payload,
                status : "success"}
        })
        .addCase(getKPIs.rejected, (state) => {
            return {
                ...state, 
                status : "rejected" }
        })

        //Get Weekly Bookings
        builder
        .addCase(getBookingsWeeklyKPIs.pending, (state) => {
            return {
                ...state, 
                status : "pending" }
        })
        .addCase(getBookingsWeeklyKPIs.fulfilled,(state, action) => {
            return {
                ...state, 
                weeklyBookings : action.payload,
                status : "success" }
        })
        .addCase(getBookingsWeeklyKPIs.rejected, (state) => {
            return {
                ...state, 
                status : "rejected" }
        })

        //Get PerformanceKPIs 
        .addCase(getPerformanceKPIs.pending,(state) => {
            return {
                ...state, status : "pending"}
        })
        .addCase(getPerformanceKPIs.fulfilled, (state, action) => {
            return {
                ...state,
                performanceKpis : action.payload,
                status : "success"}
        })
        .addCase(getPerformanceKPIs.rejected, (state) => {
            return {
                ...state, 
                status : "rejected" }
        })
    }
})

// const performanceKpisSlice = createSlice({
//     name: "perormanceKpis",
//     initialState,
//     reducers: {},
//     extraReducers : (builder) => {
//         //Get PerformanceKPIs 
//         builder
//         .addCase(getPerfomanceKPIs.pending,(state) => {
//             return {
//                 ...state, status : "pending"}
//         })
//         .addCase(getPerfomanceKPIs.fulfilled, (state, action) => {
//             return {
//                 ...state,
//                 performanceKpis : action.payload,
//                 status : "success"}
//         })
//         .addCase(getPerfomanceKPIs.rejected, (state) => {
//             return {
//                 ...state, 
//                 status : "rejected" }
//         })
        
//     }
// })

export default kpisSlice.reducer;


