import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/Axios';
import { useSelector } from "react-redux";


const initialState = {
    users: [],
    activeUsers: [],
    status: "",
    totalPages: 0
}


export const getAllUsers = createAsyncThunk(
    "users/getAllUsers",
    async (values, {rejectWithValue}) => {
        try {
            const response = await axios.post('/employees/getAllUsers',
            {
                offset: values.page,
                limit: values.pageSize
            },
            {
                headers: { 
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('accessToken')
            },
                withCredentials: false

            })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

export const getActiveUsers = createAsyncThunk(
    "users/getActiveUsers",
    async (values, {rejectWithValue}) => {
        try {
            const response = await axios.get('/employees/getActiveUsers',
            {
                headers: { 
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('accessToken')
            },
                withCredentials: false

            })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

export const createUser = createAsyncThunk(
    "users/",
    async (user, {rejectWithValue}) => {
        try {
            console.log(`The user is ${JSON.stringify(user)}`)
            const response = await axios.post('/employees/createUser',
            {
                firstName: user.firstName,
                lastName: user.lastName,
                phoneNumber: user.phoneNumber,
                countryId: user.countryId
            },
            {
                headers: { 
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('accessToken')
            },
                withCredentials: false

            })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {
        builder.addCase(getAllUsers.pending, (state, action) => {
            return {...state, status: "pending"}
        })
        builder.addCase(getAllUsers.fulfilled, (state, action) => {
            return {...state, status: "success", users: action.payload.users, totalPages: action.payload.totalPages }
        })
        builder.addCase(getAllUsers.rejected, (state, action) => {
            return {...state, status: "rejected"}
        })


        builder.addCase(getActiveUsers.pending, (state, action) => {
            return {...state, status: "pending"}
        })
        builder.addCase(getActiveUsers.fulfilled, (state, action) => {
            return {...state, status: "success", activeUsers: action.payload}
        })
        builder.addCase(getActiveUsers.rejected, (state, action) => {
            return {...state, status: "rejected"}
        })
    }
})
export default usersSlice.reducer;
