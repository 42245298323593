import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const RequireAuth = () => {
    const auth = useSelector((state)=>(state.auth))
    const location = useLocation();
    return (
        auth?.user 
        ? <Outlet/>
        : <Navigate to="/login" state = {{ from:location}} replace/>
    );
}

export default RequireAuth;