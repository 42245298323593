import "./datatable.scss";
import Moment from 'react-moment'
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBookingsToday } from '../../slices/bookingsSlice';
export const bookingsColumns = [
  { 
    field: "bookingId", 
    headerName: "Booking ID", 
    flex: 0.7
  },
  {
    field: "fullName",
    headerName: "Client Name",
    flex: 1.5,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {params.row.firstName}  {params.row.lastName}
        </div>
      );
    },
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    flex: 1
  },
  {
    field: "place",
    headerName: "Place",
    flex: 2,
    renderCell: (params) => {
      return (
        <span>Johannesburg - {params.row.locationName}</span>
      );
    }
  },
  {
    field: "totalAmount",
    headerName: "Amount",
    flex: 1,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          R{params.row.totalAmount}
        </div>
      );
    },
  },
  {
    field: "dateTimeSelected",
    headerName: "Time",
    flex: 1.4,
    renderCell: (params) => {
      return (
        <Moment date={params.row.dateTimeSelected} format="DD MMM yyyy hh:mm A"/>
      );
    },
  },
  {
    field: "payment",
    headerName: "Payment Method",
    flex: 2,
    renderCell: (params) => {
      return (
        <span>Johannesburg - {params.row.locationName}</span>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => {
      return (
        <span className={`status ${params.row.status}`}>{params.row.status}</span>
      );
    },
  },
];

const BookingsData = () => {
  //const [data, setData] = useState(state=>state.users);
  const { bookingsToday, status} = useSelector(state=>state.bookingsToday)
  //console.log(data)
  //const { kpis, status} = useSelector(state=>state.kpis)

  /*const handleDelete = (id) => {
    setData(users.filter((item) => item.userId !== userId));
  };*/

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to="/bookings/test" style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              //onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Today's Bookings
      </div>
      <DataGrid
        getRowId={(row) => row.bookingId}
        className="datagrid"
        rows={bookingsToday}
        columns={bookingsColumns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        checkboxSelection
      />
    </div>
  );
};

export default BookingsData;
