import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/Axios';
import { useSelector } from "react-redux";


const initialState = {
    bookings: [],
    latestBookings: [],
    bookingsToday: [],
    bookingsThisMonth: [],
    status: ""
}


export const getActiveBookings = createAsyncThunk(
    "bookings/getActive",
    async (values, {rejectWithValue}) => {
        try {
            const response = await axios.get('/bookings/getActiveWashes',
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'x-auth-token': localStorage.getItem('accessToken')
                },
                    withCredentials: false

                })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

export const getBookingsThisMonth = createAsyncThunk(
    "bookings/getBookingsThisMonth",
    async (values, {rejectWithValue}) => {
        try {
            const response = await axios.get('/employees/getBookingsThisMonth',
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'x-auth-token': localStorage.getItem('accessToken')
                },
                    withCredentials: false

                })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

export const getLatestBookings = createAsyncThunk(
    "bookings/getLatest",
    async (values, {rejectWithValue}) => {
        try {
            const response = await axios.get('/employees/getLatestBookings',
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'x-auth-token': localStorage.getItem('accessToken')
                },
                    withCredentials: false

                })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

export const getBookingsToday = createAsyncThunk(
    "bookings/getBookingsToday",
    async (values, {rejectWithValue}) => {
        try {
            const response = await axios.get('/employees/getBookingsToday',
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'x-auth-token': localStorage.getItem('accessToken')
                },
                    withCredentials: false

                })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

const bookingsSlice = createSlice({
    name: "bookings",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getActiveBookings.pending, (state, action) => {
            state.status = "pending";
        })
        builder.addCase(getActiveBookings.fulfilled, (state, action) => {
            state.bookings = action.payload;
            state.status = "success";
        })
        builder.addCase(getActiveBookings.rejected, (state, action) => {
            state.status = "rejected";
        })


        builder.addCase(getLatestBookings.pending, (state, action) => {
            state.status = "pending";
        })
        builder.addCase(getLatestBookings.fulfilled, (state, action) => {
            state.latestBookings = action.payload;
            state.status = "success";
        })
        builder.addCase(getLatestBookings.rejected, (state, action) => {
            state.status = "rejected";
        })


        builder.addCase(getBookingsToday.pending, (state, action) => {
            state.status = "pending";
        })
        builder.addCase(getBookingsToday.fulfilled, (state, action) => {
            state.bookingsToday = action.payload;
            state.status = "success";
        })
        builder.addCase(getBookingsToday.rejected, (state, action) => {
            state.status = "rejected";
        })

        //Get Bookings This Month
        builder.addCase(getBookingsThisMonth.pending,(state) => {
            return {
                ...state, status : "pending"}
        })
        builder.addCase(getBookingsThisMonth.fulfilled, (state, action) => {
            return {
                ...state,
                bookingsThisMonth : action.payload,
                status : "success"}
        })
        builder.addCase(getBookingsThisMonth.rejected, (state) => {
            return {
                ...state, 
                status : "rejected" }
        })


    }
})
export default bookingsSlice.reducer;
