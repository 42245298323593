import "./RecentBookings.scss";
import Moment from 'react-moment'
import FlexiGridList from "../../components/gridList/GridList";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import React, {useEffect, useState} from 'react'
import { getLatestBookings } from "../../slices/bookingsSlice";

const dateRenderCell = (params) => {
  const date = new Date(params.row.dateTimeSelected);
  const today = new Date();
  const yesterday = new Date(today);
  const tomorrow = new Date(today);

  yesterday.setDate(today.getDate() - 1);
  tomorrow.setDate(today.getDate() + 1);

  if (date.toDateString() === yesterday.toDateString()) {
    return (
      <>
        <span>Yesterday</span>
        <br />
        <span>{`at `}</span>
        <Moment date={params.row.dateTimeSelected} format="hh:mm A" />
      </>
    );
  } else if (date.toDateString() === tomorrow.toDateString()) {
    return (
      <>
        <span>Tomorrow </span> 
        <br />
        <span>{`at `}</span>
        <Moment date={params.row.dateTimeSelected} format="hh:mmA" />
      </>
    );
  } else {
    return <Moment date={params.row.dateTimeSelected} format="DD MMM yyyy hh:mmA" />;
  }
};

export const bookingColumns = [
    { 
      field: "bookingId", 
      headerName: "Ref", 
      flex: 0.5 
    },
    { 
      field: "washType", 
      headerName: "Wash Type", 
      flex: 1,
      renderCell: (params) => {
          return (
              <span>{params.row.type}</span>
          );
      }
    },
    {
      field: "fullName",
      headerName: "Client Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.firstName} {params.row.lastName}
          </div>
        );
      },
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 0.8,
    },
    {
      field: "dateTimeSelected",
      headerName: "Date and Time",
      flex: 1.3,
      renderCell: dateRenderCell
    },
    {
      field: "totalAmount",
      headerName: "Amount",
      flex: 0.6,
      renderCell: (params) => {
          return (
              <span>R{params.row.totalAmount}</span>
          );
      },
    },
    {
      field: "PaymentMethod",
      headerName: "Payment Method",
      flex: 1.3,
      renderCell: (params) => {
          return (
              <span>{params.row.paymentTypeDescription}</span>
          );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={`status ${params.row.status}`}>{params.row.status}</div>
        );
      },
    },
  ];
  const actionColumn = [
      {
        field: "action",
        headerName: "Action",
        flex: 0.4,
        renderCell: (params) => {
          return (
            <div className="cellAction">
              <Link to="/users/test" style={{ textDecoration: "none" }}>
                <div className="viewButton">View</div>
              </Link>
            </div>
          );
        },
      },
    ];

const RecentBookings = () => {
  //const { bookings, status} = useSelector(state=>state.bookings)
  const { latestBookings} = useSelector(state=>state.latestBookings)

  const dispatch = useDispatch();
  useEffect(() => {
        dispatch(getLatestBookings())
  },[])
  //const title = !latestBookings ? "Recent Bookings" : `Recent Bookings - ${latestBookings?.count} in the past 5 days`;

  return (
    <div className="recentBookingsContainer">
      <Sidebar/>
        <div className="recentBookingsSection">
            <Navbar/>
            {/* other content */}
            <FlexiGridList
                //title= "Recent Bookings"
                title={ latestBookings ? `Recent Bookings - ${latestBookings.length}` : "Recent Bookings"}
                link="/bookings"
                actionLabel="Add booking"
                data={latestBookings}
                columns={bookingColumns.concat(actionColumn)}
                pageSize={25}
                rowIdProperty="bookingId"
            />
        </div>
    </div>
  );
}

export default RecentBookings;