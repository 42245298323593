import "./datatable.scss";
import Moment from 'react-moment'
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCarWashes } from '../../slices/carWashesSlice';
export const carWashesColumns = [
  { 
    field: "carWashId", 
    headerName: "ID", 
    flex: 1 
  },
  {
    field: "name",
    headerName: "Name",
    flex: 3,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "type",
    headerName: "Type",
    flex: 2,
    renderCell: (params) => {
      return (
        <span>
          {params.row.isMobile ? "Mobile Car Wash" : "Fixed Car Wash"}
        </span>
      );
    },
  },
  {
    field: "streetAddress",
    headerName: "Address",
    flex: 3,
  },
  {
    field: "createdAt",
    headerName: "Date Joined",
    flex: 2,
    renderCell: (params) => {
      return (
        <Moment date={params.row.createdAt} format="YYYY-MM-DD hh:mm A"/>
      );
    },
  },
];

const CarWashData = () => {
  //const [data, setData] = useState(state=>state.users);
  const { carWashes, status} = useSelector(state=>state.carWashes)
  //console.log(data)
  //const { kpis, status} = useSelector(state=>state.kpis)

  /*const handleDelete = (id) => {
    setData(users.filter((item) => item.userId !== userId));
  };*/

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to="/carWashes/test" style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              //onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add New Car Wash
        <Link to="/users/new-car-wash" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        getRowId={(row) => row.carWashId}
        className="datagrid"
        rows={carWashes}
        columns={carWashesColumns.concat(actionColumn)}
        pageSize={50}
        rowsPerPageOptions={[50]}
        checkboxSelection
      />
    </div>
  );
};

export default CarWashData;
