import "./widget.scss";
const Widget = ({ title, value, actionTitle, 
                  changeValue, symbol, sign, handleClick, 
                  displayIcon:DisplayIcon, displayIconBackgroundColor, displayIconColor,
                  arrowIcon:ArrowIcon}) => {
  return (
    <div className="kpiWidgetContainer">
      <div className="left">
        <span className="title">{title}</span>
        <span className="counter">
          {symbol} {value}
        </span>
        <span className="link" onClick={handleClick}>
          {actionTitle}
      </span>
      </div>
      <div className="right">
        <div className={'percentage ' + sign} >
          <ArrowIcon/>
          {changeValue}%
        </div>
        <DisplayIcon
            className="icon"
            style={{
              backgroundColor: displayIconBackgroundColor,
              color: displayIconColor
            }}
        />
      </div>
    </div>
  );
};

export default Widget;
