import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/Axios';
import { useSelector } from "react-redux";


const initialState = {
    expenses: [],
    status: ""
}


export const getAllExpenses = createAsyncThunk(
    "getAllExpenses",
    async (data, {rejectWithValue}) => {
        try {
            const response = await axios.post('/expenses/getAllExpenses',
            {
                startDate: data.startDate,
                endDate: data.endDate,
            },
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'x-auth-token': localStorage.getItem('accessToken')
                },
                    withCredentials: false
                })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

export const createExpense = createAsyncThunk(
    "createExpese",
    async (data, {rejectWithValue}) => {
        try {
            const response = await axios.post('/expenses/createExpense',
            {
                startDate: data.startDate,
                endDate: data.endDate,
            },
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'x-auth-token': localStorage.getItem('accessToken')
                },
                    withCredentials: false
                })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

const expensesSlice = createSlice({
    name: "expenses",
    initialState,
    reducers: {
    },
    extraReducers : (builder) => {
        //Get KPIs 
        builder
        .addCase(getAllExpenses.pending,(state) => {
            return {
                ...state, 
                status : "pending" }
        })
        .addCase(getAllExpenses.fulfilled,(state, action) => {
            return {
                ...state, 
                expenses : action.payload,
                status : "success" }
        })
        .addCase(getAllExpenses.rejected,(state) => {
            return {
                ...state, 
                status : "rejected" }
        })
    }
})
export default expensesSlice.reducer;
