// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from '../api/Axios';

// const initialState = {
//     token: localStorage.getItem('accessToken'),
//     user: null,
//     _id:"",
//     registerStatus:"",
//     registerError: "",
//     loginStatus:"",
//     loginError: "",
//     userLoaded: false
// }

// export const registerUser = createAsyncThunk(
//     "auth/register",
//     async (values, {rejectWithValue}) => {
//         try {
//             await axios.post('/user', {
//                 firstName: values.firstName,
//                 lastName: values.lastName,
//                 email: values.email,
//                 password: values.password
//             })
//         } catch (err){
//             var errorMessage = err?.response?.data.replace(/['"]+/g, '')
//             errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
//             return rejectWithValue(errorMessage)
//         }
//     }
// )

// export const loginUser = createAsyncThunk(
//     "auth/login",
//     async (values, {rejectWithValue}) => {
//         try {
//             const response = await axios.post('/authEmployees', {
//                 email: values.email,
//                 password: values.password
//             })
//             localStorage.setItem("accessToken", response?.headers?.["x-auth-token"])
//             localStorage.setItem("user", JSON.stringify(response?.data))
//             return response?.data;
//         } catch (err){
//             var errorMessage = err?.response?.data.replace(/['"]+/g, '')
//             errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
//             return rejectWithValue(errorMessage)
//         }
//     }
// )

// const authSlice = createSlice({
//     name: "auth",
//     initialState,
//     reducers: {
//         loadUser(state){
//             const token = state.token;
//             const user = JSON.parse(localStorage.getItem("user"));
//             if(token != null){
//                 return {
//                     ...state, 
//                     token,
//                     user: user,
//                     _id: user?.employeeId,
//                     userLoaded: true
//                 }
//             }
//         },
//         logoutUser(state, action) {
//             localStorage.removeItem("accessToken");
//             localStorage.removeItem("user");
//             return {
//                 ...state,
//                 token: "",
//                 user:"",
//                 _id: "",
//                 registerStatus: "",
//                 registerError: "",
//                 loginStatus: "",
//                 loginError: "",
//             };
//     },
//   },
//     extraReducers: (builder) => {
//         builder.addCase(loginUser.pending, (state) => {
//             return {...state, loginStatus: "pending"}
//         })
//         builder.addCase(loginUser.fulfilled, (state, action) => {
//             if(action.payload){
//                 const user = action.payload;
//                 const token = localStorage.getItem('accessToken')
//                 return {
//                     ...state, 
//                     token,
//                     user: user,
//                     _id: user?.employeeId,
//                     loginStatus: 'success'
//                 }
//             }else return state;
//         })
//          builder.addCase(loginUser.rejected, (state, action) => {
//             return {
//                 ...state,
//                 loginStatus: "rejected",
//                 loginError: action.payload
//             }
//         })
//     }
// })
// export const { loadUser, logoutUser } = authSlice.actions;

// export default authSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/Axios';

const initialState = {
  token: localStorage.getItem('accessToken'),
  user: null,
  _id: "",
  registerStatus: "",
  registerError: "",
  loginStatus: "",
  loginError: "",
  userLoaded: false,
};

export const registerUser = createAsyncThunk("auth/register", async (values, { rejectWithValue }) => {
  try {
    await axios.post('/user', {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
    });
  } catch (err) {
    const errorMessage = (err?.response?.data || '').replace(/['"]+/g, '');
    return rejectWithValue(errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1));
  }
});

export const loginUser = createAsyncThunk("auth/login", async (values, { rejectWithValue }) => {
  try {
    const response = await axios.post('/authEmployees', {
      email: values.email,
      password: values.password,
    });

    localStorage.setItem("accessToken", response?.headers?.["x-auth-token"]);
    localStorage.setItem("user", JSON.stringify(response?.data));

    return response?.data;
  } catch (err) {
    const errorMessage = (err?.response?.data || '').replace(/['"]+/g, '');
    return rejectWithValue(errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1));
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loadUser(state) {
      const token = state.token;
      const user = JSON.parse(localStorage.getItem("user"));
      if (token) {
        return { ...state, token, user, _id: user?.employeeId, userLoaded: true };
      }
      return state;
    },
    logoutUser(state) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      return {
        ...state,
        token: "",
        user: "",
        _id: "",
        registerStatus: "",
        registerError: "",
        loginStatus: "",
        loginError: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        return { ...state, loginStatus: "pending" };
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        const user = action.payload;
        const token = localStorage.getItem('accessToken');
        return user
          ? { ...state, token, user, _id: user?.employeeId, loginStatus: 'success' }
          : state;
      })
      .addCase(loginUser.rejected, (state, action) => {
        return {
          ...state,
          loginStatus: "rejected",
          loginError: action.payload,
        };
      });
  },
});

export const { loadUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;
