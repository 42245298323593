/*// Performance.jsx
import React from 'react';
import './performance.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import CustomBarChart from '../../components/chart/BarChart';
import CustomLineChart from '../../components/chart/LineChart';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPerfomanceKPIs } from '../../slices/kpisSlice';

const Performance = () => {
    const { data, status} = useSelector(state=>state.performanceKpis)
    const dispatch = useDispatch();

    // const data = [
    //     { year: 2016, users: 114, total: 114, numberOfBookings: 0, revenue:0},
    //     { year: 2017, users: 49, total: 163, numberOfBookings:  0, revenue:0},
    //     { year: 2018, users: 763, total: 926, numberOfBookings:  245, revenue:28580},
    //     { year: 2019, users: 1700, total:  2626, numberOfBookings: 1137,revenue:151150},
    //     { year: 2020, users: 1201, total:  3827, numberOfBookings: 1905,revenue:339830},
    //     { year: 2021, users: 962, total:  4753, numberOfBookings: 2421,revenue:544130},
    //     { year: 2022, users: 869, total:  5622, numberOfBookings: 2659,revenue:739170},
    //     { year: 2023, users: 443, total:  6065, numberOfBookings: 2061,revenue:761030},
    //   ];
    
    useEffect(() => {
        dispatch(getPerfomanceKPIs());
      }, []);
      // Customize lines
      const totalUsers = [
        { dataKey: 'total', strokeColor: 'grey', name: 'Total Users' },
      ];
      const newUsersPerYear = [
        { dataKey: 'users', strokeColor: 'indigo', name: 'New Users Per Year' },
      ];
      const totalBookings = [
        { dataKey: 'numberOfBookings', strokeColor: 'blue', name: 'Total Bookings' },
      ];
      const revenuePerYear = [
        { dataKey: 'revenue', strokeColor: 'green', name: 'Revenue Per Year' },
      ];
  return (
    <div className="performanceContainer">
      <Sidebar />
      <div className="performanceSection">
        <Navbar />
        {/* other content }
        <div className="content">
        <span className='title'>
                    Business Performance - 2016 To Date</span>
          <div className="graphs-container">
            <div className="performance performance1">
                <CustomLineChart title="Number Of Users" data={data} lines={totalUsers} />
            </div>
            <div className="performance performance2">
                <CustomLineChart title="New Users Per Year" data={data} lines={newUsersPerYear} />
            </div>
            <div className="performance performance3">
                <CustomLineChart title="Bookings Per Year" data={data} lines={totalBookings} />
            </div>
            <div className="performance performance4">
                <CustomLineChart title="Revenue Per Year" data={data} lines={revenuePerYear} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Performance;*/

// Performance.jsx
import React, { useEffect } from 'react';
import './performance.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import CustomLineChart from '../../components/chart/LineChart';
import { useDispatch, useSelector } from 'react-redux';
import { getPerformanceKPIs } from '../../slices/kpisSlice';

const Performance = () => {
    const { performanceKpis } = useSelector(state => state.performanceKpis) || {};
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPerformanceKPIs());
    }, [dispatch]);
    useEffect(() => {
        // Check if performanceKpis is not null or undefined
        if (performanceKpis) {
            console.log('Performance KPIs loaded. Length:', performanceKpis);
            console.log('Performance KPIs loaded. Length:', totalUsers);
        }
    }, [performanceKpis]);


  const totalUsers = [
    { dataKey: 'accumulatedUsers', strokeColor: 'grey', name: 'Total Users' },
  ];
  const newUsersPerYear = [
    { dataKey: 'numberOfNewUsers', strokeColor: 'indigo', name: 'New Users Per Year' },
  ];
  const totalBookings = [
    { dataKey: 'numberOfBookings', strokeColor: 'blue', name: 'Total Bookings' },
  ];
  const revenuePerYear = [
    { dataKey: 'revenue', strokeColor: 'green', name: 'Revenue Per Year' },
  ];

  return (
    <div className="performanceContainer">
      <Sidebar />
      <div className="performanceSection">
        <Navbar />
        <div className="content">
          <span className="title">Business Performance - 2016 To Date</span>
          <div className="graphs-container">
          {performanceKpis && (
                <>
                    <div className="performance performance1">
                    <CustomLineChart title="Number Of Users" data={performanceKpis} lines={totalUsers} />
                    </div>
                    <div className="performance performance2">
                    <CustomLineChart title="New Users Per Year" data={performanceKpis} lines={newUsersPerYear} />
                    </div>
                    <div className="performance performance3">
                    <CustomLineChart title="Bookings Per Year" data={performanceKpis} lines={totalBookings} />
                    </div>
                    <div className="performance performance4">
                    <CustomLineChart title="Revenue Per Year" data={performanceKpis} lines={revenuePerYear} />
                    </div>
                </>
                )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Performance;

