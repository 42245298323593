import "./carWashList.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import CarWashesData from "../../components/datatable/CarWashes"

const CarWashList = () => {
  return (
    <div className="carWashesContainer">
      <Sidebar/>
      <div className="carWashesSection">
        <Navbar/>
        <CarWashesData/>
      </div>
    </div>
  )
}

export default CarWashList