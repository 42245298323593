import React, { useEffect } from 'react';
import Moment from 'react-moment';
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../slices/usersSlice";
import { useState } from "react";
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import "./userList.scss"

const userColumns = [
  { 
    field: "userId", 
    headerName: "ID", 
    flex: 1 
  },
  {
    field: "fullName",
    headerName: "Full Name",
    flex: 3,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 3,
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    flex: 2,
  },
  {
    field: "createdAt",
    headerName: "Date Joined",
    flex: 2,
    renderCell: (params) => (
      <Moment format="YYYY-MM-DD hh:mm A">{params.value}</Moment>
    ),
  },
];

const UsersList = () => {
  const { users, status } = useSelector(state => state.users);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers({ page, pageSize }));
  }, [dispatch, page, pageSize]);

  const handleDelete = (userId) => {
    // Implement delete logic here
  };

  const actionColumn = {
    field: "action",
    headerName: "Action",
    flex: 2,
    renderCell: (params) => (
      <div className="cellAction">
        <Link to={`/users/${params.row.userId}`} style={{ textDecoration: "none" }}>
          <div className="viewButton">View</div>
        </Link>
        <div className="deleteButton" onClick={() => handleDelete(params.row.userId)}>
          Delete
        </div>
      </div>
    ),
  };

  return (
    <>
    <div className="userListContainer">
            <Sidebar/>
            <div className="userListSection">
                <Navbar/>
                  <div className='content'>
                      <div className="title">
                        Add New User
                        <Link to="/users/new-user" className="link">
                          Add New
                        </Link>
                      </div>
                      <DataGrid
                        getRowId={(row) => row.userId}
                        className="datagrid"
                        rows={users.map(user => ({ ...user, fullName: `${user.firstName} ${user.lastName}` }))}
                        columns={userColumns.concat(actionColumn)}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        checkboxSelection
                        />
                    </div>
                </div>
                </div>
            </>
  );
};

export default UsersList;
