import React, {useEffect, useState} from 'react'
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import "./home.scss";
import { getKPIs } from '../../slices/kpisSlice';
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { getBookingsWeeklyKPIs } from "../../slices/kpisSlice";
import "../../utilities/NumberFormatter"
import FormatNumber from '../../utilities/NumberFormatter';
import CustomBarChart from '../../components/chart/BarChart';
import { LineChart } from 'recharts';

const Home = () => {
  const { kpis, status} = useSelector(state=>state.kpis)
  const { weeklyBookings } = useSelector(state => state.weeklyBookings);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
        dispatch(getKPIs())
  },[])
  useEffect(() => {
    dispatch(getBookingsWeeklyKPIs());
  }, []);
  const data = weeklyBookings.map((item, index) => ({
    name: item.dayName,
    Total: item.numberOfBookings,
  }));
  const showUsers = () => {
    navigate('/users'); // Navigate to the desired path
  }

  const showTodaysBookings = () => {
    navigate('/bookings-today'); // Navigate to the desired path
  }

  const showBookingsThisMonth = () => {
    navigate('/bookings-this-month'); // Navigate to the desired path
  }

  const showRecentBookings = () => {
    navigate('/recent-bookings'); // Navigate to the desired path
  }

  const showExpensesThisMonth = () => {
    navigate('/expenses'); // Navigate to the desired path
  }

  const showActiveCarWashes = () => {
    navigate('/car-washes'); // Navigate to the desired path
  }
  

  return (
    <div className="homeContainer">
      <Sidebar />
      <div className="homeSection">
        <Navbar />
        <div className='content'>
          <div className="widgets">
              <Widget  title={"REGISTERED USERS"} displayIconBackgroundColor="rgba(0, 128, 0, 0.2)" 
              displayIconColor="green" actionTitle={"See All Users"} displayIcon={PeopleOutlineIcon} 
              arrowIcon={KeyboardArrowUpIcon} symbol="" value={kpis[0]?.usersCount||0} changeValue={5} sign="positive" 
              handleClick = {showUsers} />
              <Widget  title={"REVENUE TODAY"} displayIconBackgroundColor="rgba(0, 128, 0, 0.2)" 
              displayIconColor="green" actionTitle={"Completed Bookings"} symbol="R" displayIcon={MonetizationOnOutlinedIcon} 
              arrowIcon={KeyboardArrowUpIcon}  value={ kpis[0].revenueToday || 0} changeValue={10} sign="negative" 
              handleClick = {showTodaysBookings}/>
              <Widget  title={"EXPENSES THIS MONTH"} displayIconBackgroundColor="rgba(0, 128, 0, 0.2)" 
              displayIconColor="green" displayIcon={MonetizationOnOutlinedIcon} 
              arrowIcon={KeyboardArrowUpIcon} symbol="R"  value={FormatNumber(kpis[0]?.expensesThisMonth||0)} changeValue={330} sign="negative" 
              handleClick = {showExpensesThisMonth}
              actionTitle={"See Month's Expenses"}
              />
              <Widget  title={"REVENUE THIS MONTH"} displayIconBackgroundColor="rgba(0, 128, 0, 0.2)" 
              displayIconColor="green" displayIcon={MonetizationOnOutlinedIcon} 
              arrowIcon={KeyboardArrowUpIcon} symbol="R"  value={FormatNumber(kpis[0]?.revenueThisMonth||0)} changeValue={1000} sign="negative" 
              actionTitle={"See Month's bookings"} handleClick={showBookingsThisMonth}
              />
              <Widget  title={"PROFIT THIS MONTH"} displayIconBackgroundColor="rgba(0, 128, 0, 0.2)" 
              displayIconColor="green" actionTitle={""} displayIcon={ShoppingCartOutlinedIcon} 
              arrowIcon={KeyboardArrowUpIcon} symbol="R" value={FormatNumber(31000)} changeValue={10} sign="negative" 
              handleClick = {showTodaysBookings} />
            </div>

            {/*<div className="charts">
              <Featured />
              <Chart title="Daily Items Booked" aspect={2 / 1} />
            </div>*/}
          
            <div className="widgets">
            <Widget  title={"ACTIVE USERS TODAY"} displayIconBackgroundColor="rgba(0, 128, 0, 0.2)" 
              displayIconColor="green" actionTitle={"Today's Active Users"} displayIcon={PersonOutlinedIcon} 
              arrowIcon={KeyboardArrowUpIcon} symbol="" value={FormatNumber(kpis[0]?.dailyAciveUsersCount||0)} changeValue={10} sign="negative" 
              handleClick = {showUsers} />

              <Widget  title={"BOOKINGS TODAY"} displayIconBackgroundColor="rgba(0, 128, 0, 0.2)" 
              displayIconColor="green" actionTitle={"Today's Bookings"} displayIcon={ShoppingCartOutlinedIcon} 
              arrowIcon={KeyboardArrowUpIcon} symbol="" value={FormatNumber(kpis[0]?.bookingsCountToday||0)} changeValue={10} sign="negative" 
              handleClick = {showTodaysBookings} />

              <Widget  title={"RECENT BOOKINGS"} displayIconBackgroundColor="rgba(0, 128, 0, 0.2)" 
              displayIconColor="green" displayIcon={MonetizationOnOutlinedIcon} 
              arrowIcon={KeyboardArrowUpIcon} symbol=""  value={FormatNumber(kpis[0]?.recentBookings||0)} changeValue={330} sign="negative" 
              actionTitle={"See Recent Bookings"} handleClick={showRecentBookings}
              />

              <Widget  title={"CORPORATE CLIENTS"} displayIconBackgroundColor="rgba(0, 128, 0, 0.2)" 
              displayIconColor="green" actionTitle={"See All"} symbol="" displayIcon={MonetizationOnOutlinedIcon} 
              arrowIcon={KeyboardArrowUpIcon} value={FormatNumber(kpis[0]?.corporateClients||0)} changeValue={10} sign="negative" 
              handleClick = {showTodaysBookings}/>

              <Widget  title={"ACTIVE CAR WASHES"} displayIconBackgroundColor="rgba(0, 128, 0, 0.2)" 
              displayIconColor="green" actionTitle={"See All"} displayIcon={PeopleOutlineIcon} 
              arrowIcon={KeyboardArrowUpIcon} symbol="" value={FormatNumber(kpis[0]?.activeCarWashes||0)} changeValue={5} sign="positive" 
              handleClick = {showActiveCarWashes} />
              
              {/*<Widget  title={"UNPAID BOOKINGS"} displayIconBackgroundColor="rgba(0, 128, 0, 0.2)" 
              displayIconColor="green" actionTitle={"See All"} displayIcon={PersonOutlinedIcon} 
              arrowIcon={KeyboardArrowUpIcon} symbol="" value={205} changeValue={10} sign="negative" 
              handleClick = {showUsers} />
              
              <Widget  title={"REVENUE THIS MONTH"} displayIconBackgroundColor="rgba(0, 128, 0, 0.2)" 
              displayIconColor="green" displayIcon={MonetizationOnOutlinedIcon} 
              arrowIcon={KeyboardArrowUpIcon} symbol="R"  value={FormatNumber(kpis[0]?.revenueThisMonth||0)} changeValue={1000} sign="negative" 
              actionTitle={"See Month's bookings"}
              />*/}
            </div>
            <div className="charts">
              <Featured />
              {/*<Chart title="Daily Items Booked" aspect={2 / 1} />*/}
              <CustomBarChart data={data} aspect={2 / 1} title="Weekly Bookings" />
            </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
