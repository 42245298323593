const FormatNumber = (number) => {
  if(number != null){
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + 'm';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'k';
    }
    return number.toString();
  }else{
    return "0"
  }
};

export default FormatNumber;