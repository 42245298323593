import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const CustomLineChart = ({ title, data, lines }) => {
  return (
    <div>
      <div className="title">{title}</div>
      <LineChart width={600} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" label={{fontSize: '1.5rem' }}/>
        <YAxis />
        <Tooltip />
        <Legend />

        {lines.map((line, index) => (
          <Line
            key={index}
            type="monotone"
            dataKey={line.dataKey}
            stroke={line.strokeColor}
            activeDot={{ r: 8 }}
            name={line.name}
          />
        ))}
      </LineChart>
    </div>
  );
};

export default CustomLineChart;