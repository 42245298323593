import "./bookingsThisMonth.scss";
import Moment from 'react-moment'
import FlexiGridList from "../../components/gridList/GridList";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import { getBookingsThisMonth } from "../../slices/bookingsSlice";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const bookingColumns = [
  { 
    field: "bookingId", 
    headerName: "Ref", 
    flex: 0.5 
  },
  { 
    field: "washType", 
    headerName: "Wash Type", 
    flex: 1,
    renderCell: (params) => {
        return (
            <span>{params.row.type}</span>
        );
    }
  },
  {
    field: "fullName",
    headerName: "Client Name",
    flex: 1,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {params.row.firstName} {params.row.lastName}
        </div>
      );
    },
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    flex: 0.8,
  },
  {
    field: "dateTimeSelected",
    headerName: "Time",
    flex: 0.6,
    renderCell: (params) => {
      return (
        <Moment date={params.row.dateTimeSelected} format="hh:mm A"/>
      );
    },
  },
  {
    field: "totalAmount",
    headerName: "Amount",
    flex: 0.6,
    renderCell: (params) => {
        return (
            <span>R{params.row.totalAmount}</span>
        );
    },
  },
  {
    field: "PaymentMethod",
    headerName: "Payment Method",
    flex: 1.3,
    renderCell: (params) => {
        return (
            <span>{params.row.paymentTypeDescription}</span>
        );
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params) => {
      return (
        <div className={`status ${params.row.status}`}>{params.row.status}</div>
      );
    },
  },
];
const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to="/users/test" style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];
const BookingsThisMonth = () => {
  const { bookingsThisMonth, status} = useSelector(state=>state.bookingsThisMonth) || {};
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBookingsThisMonth());
  }, []);
  return (
    <div className="bookingsThisMonthContainer">
      <Sidebar/>
        <div className="bookingsThisMonthSection">
            <Navbar/>
            {/* other content */}
            <FlexiGridList
                title={ bookingsThisMonth ? `Bookings This Month - ${bookingsThisMonth.length}` : "Bookings This Month"}
                link="/bookings"
                actionLabel="Add booking"
                data={bookingsThisMonth}
                columns={bookingColumns.concat(actionColumn)}
                pageSize={20}
                rowIdProperty="bookingId"
            />
        </div>
    </div>
  );
}

export default BookingsThisMonth;