import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/Axios';
import { useSelector } from "react-redux";


const initialState = {
    carWashes: [],
    status: ""
}


export const getAllCarWashes = createAsyncThunk(
    "carWashes/getAllCarWashes",
    async (data, {rejectWithValue}) => {
        try {
            const response = await axios.get('/employees/getAllCarWashes',
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'x-auth-token': localStorage.getItem('accessToken')
                },
                    withCredentials: false
                })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

const carWashesSlice = createSlice({
    name: "carWashes",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getAllCarWashes.pending, (state) => {
            state.status = "pending";
        })
        builder.addCase(getAllCarWashes.fulfilled, (state, action) => {
            state.bookings = action.payload;
            state.status = "success";
        })
        builder.addCase(getAllCarWashes.rejected, (state, action) => {
            state.status = "rejected";
        })
    }
})
export default carWashesSlice.reducer;
