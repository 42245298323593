import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, TextField, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS, fr, de, it, es, pt, nl, da, sv, nb, fi, bg, el, pl, cs, ro, hu, zhCN, ja, ko, he, hr, sr, sl, et, lv, lt, ru, uk, tr, id, vi, th, sk, ar, hi, bn, msMY, msBn, faIR } from 'date-fns/locale';
import './expensesList.scss'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllExpenses } from '../../slices/expenseSlice';
const expensesColumns = [
    { 
      field: "expenseId", 
      headerName: "ID", 
      flex: 1 
    },
    {
      field: "expenseType",
      headerName: "Expense Type",
      flex: 3,
      renderCell: (params) => (
        <div>{params.row.category.description} Expense</div>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 2,
      renderCell: (params) => (
        <span>R{params.row.amount}</span>
      ),
    },
    { 
        field: "description", 
        headerName: "Description", 
        flex: 4 
    },
    {
      field: "expenseDate",
      headerName: "Expense Date",
      flex: 3,
      renderCell: (params) => (
        <Moment format="dddd, Do MMMM YYYY">{params.value}</Moment>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      flex: 2,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD hh:mm A">{params.value}</Moment>
      ),
    },
  ];
const ExpensesList = () => {
    // Calculate the current month's start date and current day
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  // Set the default start date to the beginning of the month
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  // Set the default end date to the current day of the month
  const [endDate, setEndDate] = useState(currentDate);

  const [totalExpenses, setTotalExpenses] = useState(0);

  const { expenses, status } = useSelector(state => state.expenses);
  //const [page, setPage] = useState(1);
  //const [pageSize, setPageSize] = useState(20);
  const dispatch = useDispatch();

  useEffect(() => {
    let calculatedTotalExpenses = 0;
    expenses.forEach((expense) => {
        calculatedTotalExpenses += expense.amount;
    });
    setTotalExpenses(calculatedTotalExpenses);
  }, [expenses]);

  useEffect(() => {
    dispatch(getAllExpenses({ 
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD')
     }));
  }, [dispatch]);
  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleSearch = () => {
    dispatch(getAllExpenses({ 
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD')
    }));
  };

  const handleDelete = () => {

  }

  function getLocaleForUserCountryCode(countryCode) {
    switch (countryCode) {
        case 'za':
            return enUS; // Adjust the appropriate locale for South Africa (ZA)
        // Add more cases for other country codes as needed
        default:
            return enUS; // Default to English (US) if country code is not recognized
    }
  }

  const actionColumn = {
    field: "action",
    headerName: "Action",
    flex: 2,
    renderCell: (params) => (
      <div className="cellAction">
        <Link to={`/users/${params.row.userId}`} style={{ textDecoration: "none" }}>
          <div className="viewButton">View</div>
        </Link>
        <div className="deleteButton" onClick={() => handleDelete(params.row.expenseId)}>
          Delete
        </div>
      </div>
    ),
  };

  return (
    <div className='expensesListContainer'>
        <Sidebar/>
        <div className='contentContainer'>
            <Navbar/>
            <div className='content'>
                <div className="header-section">
                <span className='title'>
                    Day to Day Expenses
                    {totalExpenses > 0 && (
                        <strong> - R{totalExpenses}</strong>
                    )}
                </span>
                    <LocalizationProvider 
                      className='date-select' 
                      dateAdapter={AdapterDateFns}
                      locale={getLocaleForUserCountryCode('za')} // Set the locale based on user's country code
                      >
                            <Box>
                            <div className='date-select'>
                                <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField {...params} />}
                                />
                                <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                minDate={startDate}
                                renderInput={(params) => <TextField {...params} />}
                                />
                                <Button variant="contained" color="primary" onClick={handleSearch}>
                                    Search
                                </Button>
                            </div>
                            </Box>
                        </LocalizationProvider>
                        <div className='add-new'>
                            <Link to="/expenses/new-expense" className="link">Add New</Link>
                        </div>
                </div>
                <DataGrid
                            getRowId={(row) => row.expenseId}
                            className="datagrid"
                            rows={expenses}
                            columns={expensesColumns.concat(actionColumn)}
                            pageSize={20}
                            rowsPerPageOptions={[20]}
                            checkboxSelection
                        />
            </div>
        </div>
      
      {/* Display Expenses List */}
      {/* Replace with actual expenses list */}
    </div>
  );
};

export default ExpensesList;
