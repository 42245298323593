import React, {useEffect, useState} from 'react'
import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getKPIs } from '../../slices/kpisSlice';
import FormatNumber from '../../utilities/NumberFormatter';

const Featured = () => {
  const { kpis, status} = useSelector(state=>state.kpis)
  const dispatch = useDispatch();
  useEffect(() => {
        dispatch(getKPIs())
  },[])
  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Total Revenue Today</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar value={(kpis[0]?.revenueToday/4800)*100} text={Number(((kpis[0]?.revenueToday/4800)*100).toFixed(1))+'%'} strokeWidth={5} />
        </div>
        <p className="title">Total sales made today</p>
        <p className="amount">R{FormatNumber(kpis[0]?.revenueToday)}</p>
        <p className="desc">
          Previous transactions processing. Last payments may not be included.
        </p>
        <div className="summary">
          <div className="item">
            <div className="itemTitle">Target</div>
            <div className="itemResult negative">
              <KeyboardArrowDownIcon fontSize="small"/>
              <div className="resultAmount">R{FormatNumber(4800)}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Week</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small"/>
              <div className="resultAmount">R{FormatNumber(kpis[0]?.revenueLastWeek)}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Month</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small"/>
              <div className="resultAmount">R{FormatNumber(kpis[0]?.revenueLastMonth)}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">This Year</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small"/>
              <div className="resultAmount">R{FormatNumber(kpis[0]?.revenueThisYear)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Featured;
