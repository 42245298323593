// // import "./chart.scss";
// // import React, {useEffect, useState} from 'react'
// // import { useDispatch, useSelector } from "react-redux";

// // import {
// //   BarChart,
// //   Bar,
// //   XAxis,
// //   YAxis,
// //   CartesianGrid,
// //   Tooltip,
// //   Legend,
// //   ResponsiveContainer,
// // } from "recharts";

// // import { getBookingsWeeklyKPIs } from "../../slices/kpisSlice";

// // const Chart = ({ aspect, title }) => {
// //   const { weeklyBookings} = useSelector(state=>state.weeklyBookings)
// //   const dispatch = useDispatch();
// //   useEffect(() => {
// //         dispatch(getBookingsWeeklyKPIs())
// //   },[])
// //   const data = [
// //   { name: "Sunday", Total: weeklyBookings[0].numberOfBookings },
// //   { name: "Monday", Total: weeklyBookings[1].numberOfBookings },
// //   { name: "Tuesday", Total: weeklyBookings[2].numberOfBookings },
// //   { name: "Wednesday", Total: weeklyBookings[3].numberOfBookings },
// //   { name: "Thursday", Total: weeklyBookings[4].numberOfBookings },
// //   { name: "Friday", Total: weeklyBookings[5].numberOfBookings },
// //   { name: "Saturday", Total: weeklyBookings[6].numberOfBookings },
// // ];

// //   return (
// //     <div className="chart">
// //       <div className="title">{title}</div>
// //       <ResponsiveContainer width="100%" aspect={aspect}>
// //         <BarChart
// //           width={730}
// //           height={250}
// //           data={data}
// //           margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
// //         >
// //           <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
// //           <XAxis dataKey="name" stroke="gray" />
// //           <YAxis />
// //           <Tooltip />
// //           <Legend />
// //           <Bar 
// //             type="monotone"
// //             dataKey="Total"
// //             stroke="#8884d8"
// //             label="Total Bookings"
// //             fillOpacity={1} 
// //             fill="#8884d8"/>
// //         </BarChart>
// //       </ResponsiveContainer>
// //     </div>
// //   );
// // }

// // export default Chart;

// import React from 'react';
// import PropTypes from 'prop-types';
// import "./chart.scss";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";

// const CustomBarChart = ({ data, aspect, title }) => {
//   return (
//     <div className="chart">
//       <div className="title">{title}</div>
//       <ResponsiveContainer width="100%" aspect={aspect}>
//         <BarChart
//           width={730}
//           height={250}
//           data={data}
//           margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
//         >
//           <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
//           <XAxis dataKey="name" stroke="gray" />
//           <YAxis />
//           <Tooltip />
//           <Legend />
//           <Bar
//             type="monotone"
//             dataKey="Total"
//             stroke="#8884d8"
//             label="Total Bookings"
//             fillOpacity={1}
//             fill="#8884d8"
//           />
//         </BarChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// CustomBarChart.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       name: PropTypes.string.isRequired,
//       Total: PropTypes.number.isRequired,
//     })
//   ).isRequired,
//   aspect: PropTypes.number.isRequired,
//   title: PropTypes.string.isRequired,
// };

// export default CustomBarChart;

import React from 'react';
import PropTypes from 'prop-types';
import "./chart.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const Chart = ({ data, aspect, title }) => {
  return (
    <div className="chart">
      <div className="title">{title}</div>
      <ResponsiveContainer width="100%" aspect={aspect}>
        <BarChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <XAxis dataKey="name" stroke="gray" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            type="monotone"
            dataKey="Total"
            stroke="#8884d8"
            fillOpacity={1}
            fill="#8884d8"
          >
          <LabelList
            dataKey="Total"
            position="insideTop"
            style={{ fill: 'white' }} // Set the label color to white
          />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      Total: PropTypes.number.isRequired,
    })
  ).isRequired,
  aspect: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default Chart;


