import React, {useRef, useState, useEffect, useContext} from 'react'
import "./login.scss"
import FormControl from '@mui/material/FormControl';
import axios from '../../api/Axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/UseAuth';
import { loginUser } from "../../slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from '@mui/material';
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const userRef = useRef();
    const errRef = useRef();
    const auth = useSelector((state) => state.auth);
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const [user, setUser] = useState({
        email: "",
        password: ""
      });
     useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    useEffect(() => {
      if (auth._id) {
        navigate("/");
      }
    }, [auth._id, navigate]);
    useEffect( () => {
      setErrorMessage('')
    },[email,password]);
  
  const handleLogin = (e) => {
     e.preventDefault();
     dispatch(loginUser(user));
  };
  return (
    <section className='login-section'>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{  
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <h2 className='heading'>Mowash Admin Login</h2>
              {auth.loginStatus ==='pending' && <p auth>Logging in ...</p>}
              <Box component="form" onSubmit={handleLogin} noValidate >
                <TextField 
                  type="email"
                  id="email" 
                  label="Email Address" 
                  variant="filled" 
                  required
                  margin="normal"
                  fullWidth
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  />
                  <TextField
                    margin="normal"
                    variant="filled" 
                    required
                    fullWidth
                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
              <button className='user-login-button'>Login</button>
          </Box>
        </Box>
      </Container>
    </section>)
}
export default Login;