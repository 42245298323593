import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import { TextField, Button, InputLabel, Select, MenuItem, Alert, InputAdornment } from '@mui/material';
import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../slices/usersSlice";
import './newExpense.scss'
import { createExpense } from "../../slices/expenseSlice";
const NewExpense = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  //const [countries, setCountries] = useState([]);
  const { countries, status} = useSelector(state=>state.countries)
  const [selectedCountry, setSelectedCountry] = useState('');

  const [expense, setExpense] = useState({
    expenseTypeId: "",
    amount: "",
    owenerId: "",
    countryId: ""
  });
  const dispatch = useDispatch();
  const handleExpense = (e) => {
    e.preventDefault();
    dispatch(createExpense(expense));
 };
  return (
    <div className="newExpenseContainer">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <Box component="form" onSubmit={handleExpense} >
              <TextField
                label="First Name"
                variant="filled"
                fullWidth
                onChange={(e) => setExpense({ ...expense, firstName: e.target.value })}
                required
              />
              <TextField
                label="Amount"
                variant="outlined"
                type="number"
                onChange={(e) => setExpense({ ...expense, firstName: e.target.value })}
                InputProps={{
                    startAdornment: <InputAdornment position="start">R</InputAdornment>,
                    inputProps: {
                    step: 0.01, // Control decimal precision
                    min: 0, // Set minimum value
                    },
                }}
                />
              <TextField
                label="Phone Number"
                variant="filled"
                fullWidth
                onChange={(e) => setExpense({ ...expense, amount: e.target.value })}
                required
              />

              <TextField
                label="Email"
                variant="filled"
                type="email"
                fullWidth
                onChange={(e) => setExpense({ ...expense, amount: e.target.value })}
                required
              />
              <FormControl fullWidth variant="filled">
                <InputLabel>Country</InputLabel>
                <Select
                  onChange={(e) => setExpense({ ...expense, countryId: e.target.value })}
                  required
                >
                  {countries.map((country) => (
                    <MenuItem key={country.countryId} value={country.countryId}>
                      {country.countryName}
                    </MenuItem>
                  ))}
                  </Select>
              </FormControl>
              <Button  type="submit" variant="contained" color="primary">
                Create User
              </Button>
            </Box>
            {status === "error" && (
                <Alert severity="error">This is an error alert — check it out!</Alert>
              )}
              {status === "success" && (
                <Alert severity="success">This is a success alert — check it out!</Alert>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewExpense;
