import "./table.scss";
import React, {useEffect, useState} from 'react'
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { getLatestBookings } from "../../slices/bookingsSlice";
import Moment from 'react-moment'

const List = () => {
  const { latestBookings} = useSelector(state=>state.latestBookings)
  const dispatch = useDispatch();
  useEffect(() => {
        dispatch(getLatestBookings())
  },[])
  return (
    <div className="tableContainer">
    <div className="title">
        Recent Bookings
    </div>
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell"><b>Ref</b></TableCell>
            <TableCell className="tableCell"><b>Wash Type</b></TableCell>
            <TableCell className="tableCell"><b>Full Name</b></TableCell>
            <TableCell className="tableCell"><b>Phone</b></TableCell>
            <TableCell className="tableCell"><b>Date Selected</b></TableCell>
            <TableCell className="tableCell"><b>Amount</b></TableCell>
            <TableCell className="tableCell"><b>Payment Method</b></TableCell>
            <TableCell className="tableCell"><b>Status</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {latestBookings.map((row) => (
            <TableRow key={row.id}>
              <TableCell className="tableCell">{row.bookingId}</TableCell>
              <TableCell className="tableCell">{row.type}</TableCell>
              <TableCell className="tableCell">{row.firstName} {row.lastName}</TableCell>
              <TableCell className="tableCell">{row.phoneNumber}</TableCell>
              <TableCell className="tableCell"> {formatDate(row.dateTimeSelected)} at <Moment date={row.dateTimeSelected} format="hh:mm A"/></TableCell>
              <TableCell className="tableCell">R{row.totalAmount}</TableCell>
              <TableCell className="tableCell">{row.paymentTypeDescription}</TableCell>
              <TableCell className="tableCell">
                <span className={`status ${row.status}`}>{row.status}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};

function formatDate(dateString){
   const today = new Date();
   const today_ = new Date();
   const today__ = new Date();
   const yesterday = new Date(today_.setDate(today_.getDate() - 1)).toDateString()
   const tomorrow = new Date(today__.setDate(today__.getDate() + 1)).toDateString()
   if (today.toDateString() === new Date(dateString).toDateString()) {
    return "Today";
  }else if (yesterday === new Date(dateString).toDateString()){
    return "Yesterday";
  }else if (tomorrow === new Date(dateString).toDateString()){
    return "Tomorrow";
  }
  return new Date(dateString).toDateString();
}

export default List;
