import React from 'react';
import Moment from 'react-moment';
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import "./GridList.scss"

const GridList = ({ title, link, actionLabel, data, columns, pageSize, rowIdProperty }) => {
  const getRowId = (row) => {
    return row[rowIdProperty];
  };

  return (
    <div className="gridList">
      <div className="gridListTitle">
        {title}
        <Link to={link} className="link">
          {actionLabel}
        </Link>
      </div>
      <DataGrid
        getRowId={getRowId}
        className="datagrid"
        rows={data}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        checkboxSelection
      />
    </div>
  );
};

export default GridList;
