import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/Axios';
import { useSelector } from "react-redux";


const initialState = {
    countries: [],
    status: ""
}

export const getCountries = createAsyncThunk(
    "countries",
    async (values, {rejectWithValue}) => {
        try {
            const response = await axios.get('/countries',
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'x-auth-token': localStorage.getItem('accessToken')
                },
                    withCredentials: false

                })
            return response?.data
        } catch (err){
            var errorMessage = err?.response?.data.replace(/['"]+/g, '')
            errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
            return rejectWithValue(errorMessage)
        }
    }
)

const countriesSlice = createSlice({
    name: "countries",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getCountries.pending, (state, action) => {
            return {...state, status: "pending"}
        })
        builder.addCase(getCountries.fulfilled, (state, action) => {
            return {...state, countries : action.payload, status: "success"}
        })
        builder.addCase(getCountries.rejected, (state, action) => {
            return {...state, status: "rejected"}
        })
    }
})
export default countriesSlice.reducer;
