import React from 'react'
import './activeUsersList.scss'
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Moment from 'react-moment';
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { getActiveUsers } from '../../slices/usersSlice';
import CustomBarChart from '../../components/chart/Chart';
const userColumns = [
    { 
      field: "id", 
      headerName: "Position", 
      flex: 1 
    },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 3
    },
    {
      field: "numberOfBookings",
      headerName: "Number Of Bookings",
      flex: 2,
    },
    {
      field: "lastBookingDate",
      headerName: "Last Booking Date",
      flex: 2,
      renderCell: (params) => (
        <Moment format="YYYY-MM-DD hh:mm A">{params.value}</Moment>
      ),
    },
  ];
  const actionColumn = {
    field: "action",
    headerName: "Action",
    flex: 1,
    renderCell: (params) => (
      <div className="cellAction">
        <Link to={`/users/${params.row.userId}`} style={{ textDecoration: "none" }}>
          <div className="viewButton">View</div>
        </Link>
      </div>
    ),
  };

const ActiveUsersList = () => {
    const { activeUsers, status } = useSelector(state => state.activeUsers);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getActiveUsers());
      }, []);
      const data = activeUsers.map((item, index) => ({
        name: item.firstName,
        Total: item.numberOfBookings,
      }));
      const title = !activeUsers ? "Top 20 Active Users" : `Top Active 20 Users - Total ${activeUsers[0]?.count}`;

  return (
    <div className="activeUsersContainer">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <div className='content'>
            <div className='chart-area'>
                <CustomBarChart data={data} aspect={4 / 1} title={title} />
            </div>
            <div className='table-area'>
            <DataGrid
                    getRowId={(row) => row.id}
                    className="datagrid"
                    rows={activeUsers.map(user => ({ ...user, fullName: `${user.firstName} ${user.lastName}` }))}
                    columns={userColumns.concat(actionColumn)}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    checkboxSelection
                    />
            </div>
        </div>
      </div>
    </div>
  )
}

export default ActiveUsersList;